const config = {
  stripe: {
    createCustomerUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/create-customer',
    retrieveCustomerUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/retrieve-customer',
    addCustomerToUserUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/add-customer-to-user',
    updateCustomerUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/update-customer',
    createSourceUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/create-source',
    attachSourceUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/attach-source',
    createPaymentIntentUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/create-payment-intent',
    updatePaymentIntentUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/update-payment-intent',
    refundPaymentIntentUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/refund-payment-intent',
    retrievePaymentIntentsUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/retrieve-payment-intents',
    createChargeUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/create-charge',
    updateChargeUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/update-charge',
    refundChargeUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/refund-charge',
    retrieveChargesUrl: 'https://fllegsmrug.execute-api.us-east-1.amazonaws.com/dev/retrieve-charges',

  },
  cloudbeds: {
    checkTokenUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/check-token',
    getTokenUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/get-token',
    getRatePlansUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/get-rate-plans',
    getGuestUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/get-guest',
    putGuestUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/putGuest',
    postReservationUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/post-reservation',
    postReservationItemUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/post-reservation-item',
    postReservationNotesUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/post-reservation-notes',
    postPaymentUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/post-payment',

    postArrivalTimeUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/postArrivalTime',
    postInvoiceUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/postInvoice',
    getReservationUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/getReservation',
    getInvoiceInfoUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/getInvoiceInfo',
    confirmReservationUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/confirmReservation',
    cancelReservationUrl: 'https://0710c8kdac.execute-api.us-east-1.amazonaws.com/dev/cancelReservation',

  },
};

export default config;
